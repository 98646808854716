body {
    margin-top: 65px;
}

.navbar-header {
    position: relative;
    top: -4px;
}

.navbar-brand > .icon-banner {
    position: relative;
    top: -2px;
    display: inline;
}

.icon {
    position: relative;
    top: -10px;
}

.logged-out iframe {
    display: none;
    width: 0;
    height: 0;
}

.page-consent {
    .client-logo {
        float: left;

        img {
            width: 80px;
            height: 80px;
        }
    }

    .consent-buttons {
        margin-top: 25px;
    }

    .consent-form {
        .consent-scopecheck {
            display: inline-block;
            margin-right: 5px;
        }

        .consent-scopecheck[disabled] {
            //visibility:hidden;
        }

        .consent-description {
            margin-left: 25px;

            label {
                font-weight: normal;
            }
        }

        .consent-remember {
            padding-left: 16px;
        }
    }
}

.grants {
    .page-header {
        margin-bottom: 10px;
    }

    .grant {
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid lightgray;

        img {
            width: 110px;
            height: 110px;
        }

        .clientname {
            font-size: 140%;
            font-weight: bold;
        }

        .granttype {
            font-size: 120%;
            font-weight: bold;
        }

        .created {
            font-size: 120%;
            font-weight: bold;
        }

        .expires {
            font-size: 120%;
            font-weight: bold;
        }

        li {
            list-style-type: none;
            display: inline;

            &:after {
                content: ', ';
            }

            &:last-child:after {
                content: '';
            }
        }
    }
}

.btn-primary {
    color: #fff;
    background-color: #00b09b;
    border-color: #d5d2db;
}

.btn-primary:hover {
    color: #fff;
    background-color: #008a79;
    border-color: #bab6c4;
}
  
.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #008a79;
    border-color: #bab6c4;
    box-shadow: 0 0 0 0.2rem rgba(219, 217, 224, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #00b09b;
    border-color: #d5d2db;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #007d6e;
    border-color: #b4afbe;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(219, 217, 224, 0.5);
}

.portal-list {
    display: table;
    margin: 0 auto;
    list-style-position: outside;
}
  
.login-toggle-bar {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 25px;

    .btn {
        width: 150px;
    }
}

